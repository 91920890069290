import { Box, IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import React, { FC } from 'react'

interface SwiperNavigationProps {
  swiperRef: any
}

const SwiperNavigation: FC<SwiperNavigationProps> = props => {
  const { swiperRef } = props

  const handleSwipNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const handleSwipPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box marginRight={2}>
        <IconButton size="small" onClick={handleSwipPrev}>
          <ArrowBackIcon color="primary" />
        </IconButton>
      </Box>
      <Box>
        <IconButton size="small" onClick={handleSwipNext}>
          <ArrowForwardIcon color="primary" />
        </IconButton>
      </Box>
    </Box>
  )
}

export default SwiperNavigation
